import React, { useState, useEffect, useContext, useRef } from "react";
import "./dataComponentEmp.css";
import { AuthContext } from "../authProvider/authProvider";
import { Navigate, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";

import { faEdit, faIdCard, faUserTimes, faEye, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore, DocumentData } from "firebase/firestore";
import { app, host } from "../../config";

import Modal from "react-modal";

import { FormControlLabel, Grid, Pagination, Radio, RadioGroup, TextField } from "@mui/material";
import { userInfo } from "os";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";

import { Checkbox, FormControl } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { jsPDF } from "jspdf";

const auth = getAuth();
const db = getFirestore(app);

type Flag = {
  id: number;
  name: string;
};

export type SortedWLTDataType = {
  [key: string]: WLTDataType[];
};

export type WLTDataType = {
  [key: string]: string | [Flag];
  group: string;
  locno: string;
  location: string;
  empno: string;
  depno: string;
  lastname: string;
  firstname: string;
  ssn: string;
  dob: string;
  Eclass: string;
  FromDate: string;
  ToDate: string;
  idAlt: string;
  lastFourOfSSN: string;
  addr1: string;
  addr2: string;
  city: string;
  state: string;
  zipcode: string;
  flags: [Flag];
  empKey: string;
  key: string;
  essn: string;
  edob: string;
};

type ApiDataComponentProps = {
  data: WLTDataType[];
  handleChange: any;
  handleTerminationChange: Function;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
  showTerminated: Boolean;
  search: string | null;
  setSearch: Function;
  totalPages: number;
  handlePagination: (event: React.ChangeEvent<unknown>, page: number) => void;
};

interface EmployeeTabsProps {
  currentTab: string; // Assuming currentTab is a string that denotes the active tab
  setCurrentTab: (tab: string) => void; // Function that updates the current tab
  handleTerminationChange: (terminated: boolean) => void; // Function to handle termination change
  search: string; // The current search query
  setSearch: (search: string) => void; // Function to update the search query
  expandAll: boolean; // A boolean that denotes whether all rows are expanded or not
  setExpandAll: (expand: boolean) => void; // Function to update expandAll state
  toggleExpandAll: () => void; // Function to toggle the expandAll state
  totalPages: number; // The total number of pages
  handlePagination: (event: React.ChangeEvent<unknown>, page: number) => void; // Function to handle pagination changes
  setIsLoading: (loading: boolean) => void; // Function to set the loading state
}

Modal.setAppElement("#root");

const WLTApiDataComponent: React.FC<ApiDataComponentProps> = ({
  data,
  handleChange,
  handleTerminationChange,
  setIsLoading,
  setAlertMessage,
  showTerminated,
  search,
  setSearch,
  totalPages,
  handlePagination,
}) => {
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedMember, setSelectedMember] = useState<WLTDataType | null>(null);
  const [editedMember, setEditedMember] = useState<WLTDataType | null>(null);

  const terminateDateRef = useRef(null);

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser } = authContext;

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  const [docData, setDocData] = useState<DocumentData | null>(null);

  //-----good up to here

  // Fields for the form
  const [lastName, setLastName] = useState("");
  const [addressOne, setAddressOne] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [showOrderCardForm, setShowOrderCardForm] = useState(false);
  const [tempCardNeeded, setTempCardNeeded] = useState(false);

  const [showTerminateForm, setShowTerminateForm] = useState(false);
  const [terminateDate, setTerminateDate] = useState("");
  const [termAnyDay, setTermAnyDay] = useState(false);

  const [isCobra, setIsCobra] = useState("");
  const [termReason, setTermReason] = useState("63");
  const [cobraEmail, setCobraEmail] = useState("");

  const [showCoverageModal, setShowCoverageModal] = useState(false);

  const [addDependentModal, setAddDependentModal] = useState(false);
  const [enrollmentReason, setEnrollmentReason] = useState("2");
  const [gender, setGender] = useState("Male");
  const [relationship, setRelationship] = useState("C");
  const [coverageDate, setCoverageDate] = useState("");
  const [dependentSSN, setDependentSSN] = useState("");
  const [dependentDOB, setDependentDOB] = useState("");
  const [dependentFirstName, setDependentFirstName] = useState("");
  const [dependentLastName, setDependentLastName] = useState("");

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());

  const [data2, setData2] = useState<WLTDataType[]>([]);
  const [dataNew, setDataNew] = useState<WLTDataType[]>(data);
  const [disenrollMemberCheckBox, setDisenrollMemberCheckBox] = useState(false);

  const [currentTab, setCurrentTab] = useState("active");

  const [expandAll, setExpandAll] = useState(false);

  const [isInputInteractive, setIsInputInteractive] = useState(false);

  const [email, setEmail] = useState("");

  const handleEmailChangeOrderCard = (event: any) => {
    setEmail(event.target.value);
  };

  const toggleExpandAll = () => {
    setExpandAll(!expandAll);
  };

  useEffect(() => {
    if (expandAll) {
      const allRows = new Set(dataNew.map((_, index) => index));
      setExpandedRows(allRows);
    } else {
      setExpandedRows(new Set());
    }
  }, [expandAll, dataNew]);

  useEffect(() => {
    if (showTerminated) {
      setCurrentTab("terminated");
    } else {
      setCurrentTab("active");
    }
  }, [showTerminated]);

  useEffect(() => {
    const addEssnData = data.map((item) => {
      let found = data.find((match) => item.empno === match.empno && "0" === match.depno);
      item.essn = found?.ssn as string;
      item.edob = found?.dob as string;
      return item;
    });
    const filteredData = addEssnData.filter((item) => item.depno !== "0");
    const updatedData = addEssnData.filter((item) => item.depno === "0");
    setDataNew(updatedData);
    setData2(filteredData);
  }, [data]);

  const toggleExpandRow = (index: number) => {
    const newExpandedRows = new Set(expandedRows);
    if (expandedRows.has(index)) {
      newExpandedRows.delete(index);
    } else {
      newExpandedRows.add(index);
    }
    setExpandedRows(newExpandedRows);
  };

  //--------------------good up to here

  useEffect(() => {
    async function getDocument() {
      if (currentUser == null) {
        return <Navigate to="/login" />;
      }

      let docRef = doc(db, "user-info", currentUser.uid);

      try {
        let docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDocData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
      }
    }

    getDocument();
  }, []);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (docData?.Suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  const handleChangeTabs = (event: any, newValue: string) => {
    setIsLoading(true);
    setCurrentTab(newValue);
    handleTerminationChange(newValue === "terminated");
  };

  //----good up to here

  const fieldsToDisplay2 = {
    // "Subscriber_Last_Name",
    First_Name: "First_Name",
    Last_Name: "Last_Name",
    Last_4_Of_SSN: "Last_4_Of_SSN",
    Member_Seq: "Member_Seq",
    Plan_ID: "Plan_ID",
    Plan_Name: "Plan_Name",
    Group_Coverage_Start_Date: "Display_Group_Coverage_Start_Date",
    End_Date: "Display_End_Date",
    Plan_Start_Date: "Display_Plan_Start_Date",
    Plan_End_Date: "Display_Plan_End_Date",
  };

  const fieldsToDisplay = {
    "First Name": "firstname",
    "Last Name": "lastname",
    "Last Four of SSN": "lastFourOfSSN",
    "Member ID": "idAlt",
    Plan: "location",
    "Start Date": "FromDate",
    "End Date": "ToDate",
  };

  const openModal = () => {
    setShowEditForm(true);
  };

  const closeModal = () => {
    setShowEditForm(false);
  };

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "40%", // This line controls the width of the modal
      height: "65%", // This line controls the height of the modal
    },
  };

  const modalStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "40%", // This line controls the width of the modal
      height: "85%", // This line controls the height of the modal
    },
  };

  //---- good up to here

  const handleEdit = (item: WLTDataType) => {
    // Open the form and set the member to be edited
    setSelectedMember(item);
    // Initialize the form fields
    setLastName(item.lastname);
    setAddressOne(item.addr1);
    setCity(item.city);
    setState(item.state);
    setZipCode(item.zipcode);

    openModal();
  };

  //----good up to here

  const handleSaveEdit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const benefits = {};

    selectedMember?.flags.map((x) => {
      const obj = { [x.id]: true };
      Object.assign(benefits, obj);
    });

    // Implement save changes here
    let edited_member = {
      ...selectedMember,
      lname: lastName,
      addr1: addressOne,
      city: city,
      state: state,
      zip: zipCode,
      benefits: benefits,
    };

    currentUser
      ?.getIdToken(false)
      .then((idToken: string) => {
        tk = idToken;
      })
      .catch((error: any) => {
        console.log(error);
      });

    //let params = new URLSearchParams({ groupNo: groupNumber }).toString();

    fetch(`${host}/api/enrollments/wlt/memberUpdate`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${tk}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ member: edited_member, email: currentUser.email }),
    })
      .then((response: Response) => {
        response.text().then((res) => {
          console.log("edit submitted");
          setIsLoading(false)
          setAlertMessage(`Member Has Been Successfully Updated!`);
        });
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
        setAlertMessage(
          `There Was An Error Editing The Member. Please Try Again Later And Contact Your Account Manager If The Issue Persists.`
        );
      }).finally(()=> handleChange());

    // Close the form
    closeModal();
  };

  //----good up to here

  const handleOrderCard = (item: WLTDataType) => {
    // Implement card function here
    setShowOrderCardForm(true);
    setSelectedMember(item);
    console.log("Order card for item:", item);
  };

  //----good up to here

  const handleOrderCardSubmit = () => {
    // Implement order card function here
    console.log("Order card for item:", selectedMember, "Temp card needed:", tempCardNeeded);

    setIsLoading(true);
    fetch(`${host}/api/enrollments/wlt/orderCards`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify({
        member: selectedMember,
        email: currentUser.email,
      }),
    })
      .then((response: Response) => {
        response.text().then(() => {
          setIsLoading(false);
          setAlertMessage(`A New ID Card Has Been Requested!`);
        });
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
        setAlertMessage(
          `There Was An Error Ordering Cards. Please Try Again Later And Contact Your Account Manager If The Issue Persists.`
        );
      })
      .finally(() => handleChange());

    // Close the form
    setShowOrderCardForm(false);
  };

  const handleTempCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempCardNeeded(e.target.checked);
  };

  //----good up to here

  const handleTerminate = (item: WLTDataType) => {
    // Implement terminate function here
    setShowTerminateForm(true);
    setSelectedMember(item);
    console.log("Terminate item:", item);
  };

  //----need to double check this handleTerminate function

  const handleTerminateSubmit = () => {
    if (errorMessage !== "" && errorMessage !== null) {
      alert("Please correct the error message before submitting");
      console.log("Error message:", errorMessage);
      return;
    }
    // Implement terminate function here
    console.log("Terminate item:", selectedMember, "Termination date:", terminateDate);

    var termBody = {
      member: selectedMember,
      empTermDate: terminateDate,
      isCobra: isCobra,
      termReason: termReason,
      cobraEmail: cobraEmail,
      email: currentUser.email,
    };

    setIsLoading(true);

    fetch(`${host}/api/enrollments/wlt/terminate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(termBody),
    })
      .then((response: Response) => {
        response.text().then((res) => {
          setIsLoading(false);
          setAlertMessage(`The Members Have Been Terminated!`);
        });
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
        setAlertMessage(
          `There Was An Error Terminating. Please Try Again Later And Contact Your Account Manager If The Issue Persists.`
        );
      })
      .finally(() => {
        handleChange();
        setIsLoading(false);
      });

    // Close the form
    setShowTerminateForm(false);
  };

  const handleTerminateDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //const selectedDate = new Date(e.target.value);

    const selectedDateStr = e.target.value;

    console.log("this is selected date");
    console.log(selectedDateStr);

    // Regex to check if date is in YYYY-MM-DD format
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    const selectedDate = new Date(selectedDateStr);
    // const minDate = getSixtyDaysPrior();

    // console.log("this is mindate");
    // console.log(minDate);
    if (!dateFormatRegex.test(selectedDateStr)) {
      setErrorMessage("Date format is incorrect. Please use YYYY-MM-DD.");
    } else if (isNaN(selectedDate.getTime())) {
      setErrorMessage("Invalid date. Please enter a valid date in YYYY-MM-DD format.");
    } else {
      setErrorMessage("");
    }

    //setErrorMessage("");
    setTerminateDate(e.target.value);

    // Correct way to get the last day of the current month
    if (false) {
      const lastDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);

      // Check if selected date is the last day of the month
      if (selectedDate.getDate() !== lastDayOfMonth.getDate()) {
        setErrorMessage("Only the last day of the month is allowed.");
        // Adjust the selectedDate to the last day of the current month
        selectedDate.setDate(lastDayOfMonth.getDate());

        function formatDateToYYYYMMDD(date: any) {
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const day = date.getDate().toString().padStart(2, "0");

          return `${year}-${month}-${day}`;
        }
        // Now update the state with the adjusted date
        setTerminateDate(selectedDate.toISOString().slice(0, 10));
      } else {
        setErrorMessage("");
        setTerminateDate(e.target.value);
      }
    }

    setTerminateDate(e.target.value);
  };

  const handleClearDate = () => {
    setTerminateDate("");
    setErrorMessage("");
  };

  const handleMakeInteractive = () => {
    setIsInputInteractive(true);
  };

  // Make input readOnly again when it loses focus or mouse out
  const handleMakeReadOnly = () => {
    setIsInputInteractive(false);
  };

  const handleKeyDown = (e) => {
    // List of allowed keys, add any keys you want to allow, like arrow keys for navigation
    const allowedKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Tab", "Enter"];

    if (!allowedKeys.includes(e.key)) {
      console.log("Prevented default");
      // Preventing the default action for keys not in the allowedKeys list

      console.log(e.key);
      return false;
    }
  };

  const handleInputFocus = (event) => {
    // This function will be triggered when the input field is focused.
    // It checks if the focus event is due to a mouse click (which opens the date picker)
    // and temporarily disables the keyboard interaction.
    const input = event.target;
    input.setAttribute("readonly", "true"); // Make input readonly to block keyboard on focus
    input.style.pointerEvents = "none"; // Disable pointer events to avoid immediate blur

    // Re-enable pointer events shortly after to allow date picker usage
    setTimeout(() => {
      input.style.pointerEvents = "auto";
    }, 50);
  };

  const handleInputBlur = (event) => {
    // This function re-enables the input field when it loses focus,
    // ensuring that the field is not left in a permanently disabled state.
    const input = event.target;
    input.removeAttribute("readonly"); // Remove readonly attribute when input blurs
  };

  //------- checkpoint

  const handleShowCoverage = (item: WLTDataType) => {
    // Implement show coverage function here
    setSelectedMember(item);
    setShowCoverageModal(true);
    console.log("Show coverage for item:", item);
  };

  const handleShowCoverageClose = () => {
    // Close the modal
    setShowCoverageModal(false);
  };

  const handleAddDependent = (item: WLTDataType) => {
    setSelectedMember(item);
    setAddDependentModal(true);
    console.log("Add dependent for item:", item);
  };

  const handleAddDependentClose = () => {
    setAddDependentModal(false);
  };

  //----checkpoint

  const handleSaveDependent = () => {
    const benefits = {};

    selectedMember?.flags.map((x) => {
      const obj = { [x.id]: true };
      Object.assign(benefits, obj);
    });
    // Implement save dependent logic here.
    let dependentSave = {
      edob: selectedMember?.dob ? new Date(selectedMember?.dob as string).toISOString() : null,
      hiredate: selectedMember?.FromDate ? new Date(selectedMember?.FromDate as string).toISOString() : null,
      //   is employee hire date the same as effDate?
      city: selectedMember?.city,
      zip: selectedMember?.zipcode,
      state: selectedMember?.state,
      addr1: selectedMember?.addr1,
      addr2: selectedMember?.addr2,
      email: currentUser.email,
      essn: selectedMember?.ssn,
      fname: dependentFirstName,
      lname: dependentLastName,
      gender: gender,
      plan: selectedMember?.locno,
      dssn: dependentSSN,
      ddob: dependentDOB ? new Date(dependentDOB).toISOString() : null,
      effective: coverageDate ? new Date(coverageDate).toISOString() : null,
      changeSelect: enrollmentReason,
      benefits: benefits,
      group: selectedMember?.group,
    };

    setIsLoading(true);

    fetch(`${host}/api/enrollments/wlt/addDependent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify({ dependent: dependentSave }),
    })
      .then((response: Response) => {
        response.text().then((res) => {
          console.log("dependent submitted");
        });
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    console.log("yoooo");

    // console.log(dependentSave);

    // Close the form
    handleAddDependentClose();
  };

  const handleDisenrollMemberCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisenrollMemberCheckBox(event.target.checked);
  };

  /* Custom Modal Styles */
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%", // Adjust the width to your preference
      border: "1px solid #ccc", // Optional border
      borderRadius: "10px", // Optional rounded corners
      padding: "20px", // Adjust padding to your preference
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional box shadow for aesthetics
    },
    // More styling can be applied to overlay or other parts as needed
  };

  function formatCurrency(value?: string) {
    if (!value) return value;
    const number = parseFloat(value);
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  function formatDate(dateString: string) {
    if (!dateString) return ""; // Return empty string if date is undefined or null
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  }

  // -----checkpoint
  const drawDottedLine = (
    doc: jsPDF,
    xFrom: number,
    yFrom: number,
    xTo: number,
    yTo: number,
    isVertical: boolean = false
  ) => {
    doc.setLineDash([3, 3], 0); // Set the "dotted" pattern
    if (isVertical) {
      // Draw a vertical dotted line by drawing many small lines
      let y = yFrom;
      while (y < yTo) {
        doc.line(xFrom, y, xFrom, y + 2); // Draw a small line segment
        y += 6; // The gap between segments
      }
    } else {
      // Draw a horizontal line
      doc.line(xFrom, yFrom, xTo, yTo); // Draw the line
    }
    doc.setLineDash([]); // Reset to a solid line, passing an empty array
  };

  const downloadPDF = () => {
    if (!selectedMember) return;

    const doc = new jsPDF();

    // Example data, replace with your data as needed
    const { firstname, lastname, empno, idAlt } = selectedMember;
    // Assuming Birth_Date is the date you want to include and formatted correctly
    const datePrinted = `Date Printed: ${new Date().toLocaleDateString("en-US")}`;

    doc.setFontSize(10);

    // Date Printed
    drawDottedLine(doc, 10, 2, doc.internal.pageSize.width - 60, 2); // Draw line across the page
    doc.text(datePrinted, 10, 10);
    drawDottedLine(doc, 10, 14, doc.internal.pageSize.width - 60, 14); // Draw line across the page

    const leftMargin = 5;
    const rightMargin = doc.internal.pageSize.width - 10;
    const topMargin = 5;
    const bottomMargin = doc.internal.pageSize.height - 10;
    drawDottedLine(doc, leftMargin, topMargin, leftMargin, bottomMargin, true);
    //drawDottedLine(doc, rightMargin, topMargin, rightMargin, bottomMargin, true);

    // Name
    doc.setFontSize(12);
    // drawDottedLine(doc, 10, 19, doc.internal.pageSize.width - 10, 19);
    doc.text(`Name: ${firstname} ${lastname}`, 10, 22);
    drawDottedLine(doc, 10, 24, doc.internal.pageSize.width - 60, 24); // Draw line across the page

    // ID
    doc.text(`ID: ${idAlt}`, 10, 34);
    drawDottedLine(doc, 10, 36, doc.internal.pageSize.width - 60, 36); // Draw line across the page

    // Description
    doc.setFontSize(10);
    doc.text("This is a Temporary ID Card, good for 30 days, and is not", 10, 44);
    doc.text("intended for permanent usage. Any detailed PPO Network and", 10, 49);
    doc.text("Prescription information is NOT listed on this card and may", 10, 54);
    doc.text("require direct contact with your plan administrator if these services", 10, 59);
    doc.text("are needed.", 10, 64);

    // Eligibility and Benefits
    doc.setFontSize(12);
    drawDottedLine(doc, 10, 76, doc.internal.pageSize.width - 60, 76); // Draw line above the section
    doc.text("Eligibility and Benefits:", 10, 86);
    doc.setFontSize(10);
    doc.text("Please contact OptiMed Health Plans at: 800-482-8770.", 10, 94);

    // Submitting Claims
    doc.setFontSize(12);
    drawDottedLine(doc, 10, 97, doc.internal.pageSize.width - 60, 97); // Draw line above the section
    doc.text("Submitting Claims:", 10, 107);
    doc.setFontSize(10);
    doc.text("Medical OptiMed Health Plans Claims:", 10, 115);
    doc.text("3145 Avalon Ridge Place, Suite 300", 10, 120);
    doc.text("Peachtree Corners, GA 30071", 10, 125);

    drawDottedLine(doc, 10, 132, doc.internal.pageSize.width - 60, 132); // Draw line above the section

    // drawDottedLine(doc, 50, 10, 50, 140, true); // Vertical line at x=50 from y=10 to y=140
    drawDottedLine(doc, 150, 10, 150, 140, true); // Vert

    doc.save("member-details.pdf");
  };

  function ActiveEmployeesTable() {
    // ... Your current table code here ...

    if (dataNew.length === 0) {
      return (
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Dependents</th>
                {Object.keys(fieldsToDisplay).map((key) => (
                  <th key={key}>{key.replace(/_/g, " ")}</th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
          </table>
          <div className="no-results-box">No search results found</div>
        </div>
      );
    }

    return (
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Dependents</th>
              {Object.keys(fieldsToDisplay).map((key) => (
                <th key={key}>{key}</th>
              ))}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dataNew.map((item, index) => {
              return (
                <>
                  <tr key={index}>
                    <td>
                      {item.depno === "0" && data2.some((filteredItem) => filteredItem.empno === item.empno) && (
                        <span onClick={() => toggleExpandRow(index)} className="large-iconData">
                          {expandedRows.has(index) ? (
                            <FontAwesomeIcon icon={faCaretDown} />
                          ) : (
                            <FontAwesomeIcon icon={faCaretRight} />
                          )}
                        </span>
                      )}
                    </td>
                    {Object.keys(fieldsToDisplay).map((key) => (
                      <td key={key} className="table-cell">
                        {item[fieldsToDisplay[key as keyof typeof fieldsToDisplay]] as string}
                      </td>
                    ))}
                    <td>
                      <Tooltip title="Edit this item">
                        <button className="hover-buttonDataComp" onClick={() => handleEdit(item)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Order an ID card for this item">
                        <button className="hover-buttonDataComp" onClick={() => handleOrderCard(item)}>
                          <FontAwesomeIcon icon={faIdCard} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Terminate this item">
                        <button className="hover-buttonDataComp" onClick={() => handleTerminate(item)}>
                          <FontAwesomeIcon icon={faUserTimes} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Show coverage details for this item">
                        <button className="hover-buttonDataComp" onClick={() => handleShowCoverage(item)}>
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Add a dependent for this item">
                        <button className="hover-buttonDataComp" onClick={() => handleAddDependent(item)}>
                          <FontAwesomeIcon icon={faUserPlus} />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                  {expandedRows.has(index) && (
                    <>
                      {data2
                        .filter((filteredItem) => filteredItem.empno === item.empno)
                        .map((filteredItem, filteredIndex) => (
                          <tr key={"expanded-" + filteredIndex}>
                            <td>Dependent</td> {/* This could be left empty or contain some icon/label */}
                            {Object.keys(fieldsToDisplay).map((key) => (
                              <td key={"expanded-" + key}>
                                {filteredItem[fieldsToDisplay[key as keyof typeof fieldsToDisplay]] as string}
                              </td>
                            ))}
                            <td>
                              {/* This could be left empty or contain some other action buttons */}
                              <Tooltip title="Edit this item">
                                <button className="hover-buttonDataComp" onClick={() => handleEdit(filteredItem)}>
                                  <FontAwesomeIcon icon={faEdit} />
                                </button>
                              </Tooltip>
                              <Tooltip title="Order an ID card for this item">
                                <button className="hover-buttonDataComp" onClick={() => handleOrderCard(filteredItem)}>
                                  <FontAwesomeIcon icon={faIdCard} />
                                </button>
                              </Tooltip>
                              <Tooltip title="Terminate this item">
                                <button className="hover-buttonDataComp" onClick={() => handleTerminate(filteredItem)}>
                                  <FontAwesomeIcon icon={faUserTimes} />
                                </button>
                              </Tooltip>
                              <Tooltip title="Show coverage details for this item">
                                <button
                                  className="hover-buttonDataComp"
                                  onClick={() => handleShowCoverage(filteredItem)}
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                </button>
                              </Tooltip>
                            </td>
                          </tr>
                        ))}
                    </>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function TerminatedEmployeesTable() {
    // ... Your current table code here ...

    if (dataNew.length === 0) {
      return (
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Dependents</th>
                {Object.keys(fieldsToDisplay).map((key) => (
                  <th key={key}>{key.replace(/_/g, " ")}</th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
          </table>
          <div className="no-results-box">No search results found</div>
        </div>
      );
    }

    return (
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Dependents</th>
              {Object.keys(fieldsToDisplay).map((key) => (
                <th key={key}>{key}</th>
              ))}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dataNew.map((item, index) => {
              return (
                <>
                  <tr key={index}>
                    <td>
                      {item.depno === "0" && data2.some((filteredItem) => filteredItem.empno === item.empno) && (
                        <span onClick={() => toggleExpandRow(index)} className="large-iconData">
                          {expandedRows.has(index) ? (
                            <FontAwesomeIcon icon={faCaretDown} />
                          ) : (
                            <FontAwesomeIcon icon={faCaretRight} />
                          )}
                        </span>
                      )}
                    </td>
                    {Object.keys(fieldsToDisplay).map((key) => (
                      <td key={key} className="table-cell">
                        {item[fieldsToDisplay[key as keyof typeof fieldsToDisplay]] as string}
                      </td>
                    ))}
                    <td>
                      <Tooltip title="Edit this item">
                        <button className="hover-buttonDataComp" onClick={() => handleEdit(item)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Order an ID card for this item">
                        <button className="hover-buttonDataComp" onClick={() => handleOrderCard(item)}>
                          <FontAwesomeIcon icon={faIdCard} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Terminate this item">
                        <button className="hover-buttonDataComp" onClick={() => handleTerminate(item)}>
                          <FontAwesomeIcon icon={faUserTimes} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Show coverage details for this item">
                        <button className="hover-buttonDataComp" onClick={() => handleShowCoverage(item)}>
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Add a dependent for this item">
                        <button className="hover-buttonDataComp" onClick={() => handleAddDependent(item)}>
                          <FontAwesomeIcon icon={faUserPlus} />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                  {expandedRows.has(index) && (
                    <>
                      {data2
                        .filter((filteredItem) => filteredItem.empno === item.empno)
                        .map((filteredItem, filteredIndex) => (
                          <tr key={"expanded-" + filteredIndex}>
                            <td>Dependent</td> {/* This could be left empty or contain some icon/label */}
                            {Object.keys(fieldsToDisplay).map((key) => (
                              <td key={"expanded-" + key}>
                                {filteredItem[fieldsToDisplay[key as keyof typeof fieldsToDisplay]] as string}
                              </td>
                            ))}
                            <td>
                              {/* This could be left empty or contain some other action buttons */}
                              <Tooltip title="Edit this item">
                                <button className="hover-buttonDataComp" onClick={() => handleEdit(filteredItem)}>
                                  <FontAwesomeIcon icon={faEdit} />
                                </button>
                              </Tooltip>
                              <Tooltip title="Order an ID card for this item">
                                <button className="hover-buttonDataComp" onClick={() => handleOrderCard(filteredItem)}>
                                  <FontAwesomeIcon icon={faIdCard} />
                                </button>
                              </Tooltip>
                              <Tooltip title="Terminate this item">
                                <button className="hover-buttonDataComp" onClick={() => handleTerminate(filteredItem)}>
                                  <FontAwesomeIcon icon={faUserTimes} />
                                </button>
                              </Tooltip>
                              <Tooltip title="Show coverage details for this item">
                                <button
                                  className="hover-buttonDataComp"
                                  onClick={() => handleShowCoverage(filteredItem)}
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                </button>
                              </Tooltip>
                            </td>
                          </tr>
                        ))}
                    </>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <div className="header">
        <h1>Employee Maintenance</h1>
        <p>NOTE: Your changes will reflect 24 hours after submission.</p>
      </div>

      <div className="tabsContainer">
        <div className="tabGroup">
          <Box className="tabsContainer2">
            {/* Replace this part with Material UI Tabs */}
            <Tabs value={currentTab} onChange={handleChangeTabs} aria-label="employee status tabs" className="myTabs">
              <Tab value="active" label="Active Employees" />
              <Tab value="terminated" label="Terminated Employees" />
            </Tabs>

            {/* ... rest of your code for the TextField, Expand all Rows, and Pagination */}
          </Box>
        </div>

        <div className="tabs">
          <div>
            <TextField
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value && e.target.value != "") {
                  setExpandAll(true);
                } else {
                  setExpandAll(false);
                }
              }}
              placeholder="Search employees"
              sx={{ input: { background: "white" } }}
            />
          </div>
          <div className={`tab ${expandAll ? "active" : ""}`} onClick={toggleExpandAll}>
            Expand all Rows
          </div>

          <div>
            <Pagination count={totalPages} onChange={handlePagination} />
          </div>
        </div>
      </div>

      {/* <br /> */}

      {/* <Pagination count={totalPages} onChange={handlePagination} /> */}

      {currentTab === "active" && <ActiveEmployeesTable />}
      {currentTab === "terminated" && <TerminatedEmployeesTable />}

      {/* if (dataNew.length === 0) {
    return (
      <div>
        <div className="header">
          <h1>Employee Maintenance</h1>
          <p>NOTE: Your changes will reflect 24 hours after submission.</p>
        </div>

        <div className="tabs">
          <div>
            <Pagination count={totalPages} onChange={handlePagination} />
          </div>
          <div className={`tab ${expandAll ? "active" : ""}`} onClick={toggleExpandAll}>
            Show all Rows
          </div>
          <div>
            <TextField
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value && e.target.value != "") {
                  setExpandAll(true);
                } else {
                  setExpandAll(false);
                }
              }}
              placeholder="Search employees"
              sx={{ input: { background: "white" } }}
            />
          </div>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th>Dependents</th>
                {Object.keys(fieldsToDisplay).map((key) => (
                  <th key={key}>{key.replace(/_/g, " ")}</th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
          </table>
          <div className="no-results-box">No search results found</div>
        </div>
      </div>
    );
  } */}

      {/* return (
    <div>
      <div className="header">
        <h1>Employee Maintenance</h1>
        <p>NOTE: Your changes will reflect 24 hours after submission.</p>
      </div>

      <div className="tabs">
        <div>
          <Pagination count={totalPages} onChange={handlePagination} />
        </div>
        <div className={`tab ${expandAll ? "active" : ""}`} onClick={toggleExpandAll}>
          Show all Rows
        </div>
        <div>
          <TextField
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              if (e.target.value && e.target.value != "") {
                setExpandAll(true);
              } else {
                setExpandAll(false);
              }
            }}
            placeholder="Search employees"
            sx={{ input: { background: "white" } }}
          />
        </div>
      </div> */}
      {/* 
      <table>
        <thead>
          <tr>
            <th>Dependents</th>
            {Object.keys(fieldsToDisplay).map((key) => (
              <th key={key}>{key}</th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {dataNew.map((item, index) => {
            return (
              <>
                <tr key={index}>
                  <td>
                    {item.depno === "0" && data2.some((filteredItem) => filteredItem.empno === item.empno) && (
                      <span onClick={() => toggleExpandRow(index)} className="large-iconData">
                        {expandedRows.has(index) ? (
                          <FontAwesomeIcon icon={faCaretDown} />
                        ) : (
                          <FontAwesomeIcon icon={faCaretRight} />
                        )}
                      </span>
                    )}
                  </td>
                  {Object.keys(fieldsToDisplay).map((key) => (
                    <td key={key} className="table-cell">
                      {item[fieldsToDisplay[key as keyof typeof fieldsToDisplay]] as string}
                    </td>
                  ))}
                  <td>
                    <Tooltip title="Edit this item">
                      <button className="hover-buttonDataComp" onClick={() => handleEdit(item)}>
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </Tooltip>
                    <Tooltip title="Order an ID card for this item">
                      <button className="hover-buttonDataComp" onClick={() => handleOrderCard(item)}>
                        <FontAwesomeIcon icon={faIdCard} />
                      </button>
                    </Tooltip>
                    <Tooltip title="Terminate this item">
                      <button className="hover-buttonDataComp" onClick={() => handleTerminate(item)}>
                        <FontAwesomeIcon icon={faUserTimes} />
                      </button>
                    </Tooltip>
                    <Tooltip title="Show coverage details for this item">
                      <button className="hover-buttonDataComp" onClick={() => handleShowCoverage(item)}>
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                    </Tooltip>
                    <Tooltip title="Add a dependent for this item">
                      <button className="hover-buttonDataComp" onClick={() => handleAddDependent(item)}>
                        <FontAwesomeIcon icon={faUserPlus} />
                      </button>
                    </Tooltip>
                  </td>
                </tr>
                {expandedRows.has(index) && (
                  <>
                    {data2
                      .filter((filteredItem) => filteredItem.empno === item.empno)
                      .map((filteredItem, filteredIndex) => (
                        <tr key={"expanded-" + filteredIndex}>
                          <td>Dependent</td> 
                          {Object.keys(fieldsToDisplay).map((key) => (
                            <td key={"expanded-" + key}>
                              {filteredItem[fieldsToDisplay[key as keyof typeof fieldsToDisplay]] as string}
                            </td>
                          ))}
                          <td>
                          
                            <Tooltip title="Edit this item">
                              <button className="hover-buttonDataComp" onClick={() => handleEdit(filteredItem)}>
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                            </Tooltip>
                            <Tooltip title="Order an ID card for this item">
                              <button className="hover-buttonDataComp" onClick={() => handleOrderCard(filteredItem)}>
                                <FontAwesomeIcon icon={faIdCard} />
                              </button>
                            </Tooltip>
                            <Tooltip title="Terminate this item">
                              <button className="hover-buttonDataComp" onClick={() => handleTerminate(filteredItem)}>
                                <FontAwesomeIcon icon={faUserTimes} />
                              </button>
                            </Tooltip>
                            <Tooltip title="Show coverage details for this item">
                              <button className="hover-buttonDataComp" onClick={() => handleShowCoverage(filteredItem)}>
                                <FontAwesomeIcon icon={faEye} />
                              </button>
                            </Tooltip>
                      
                          </td>
                        </tr>
                      ))}
                  </>
                )}
              </>
            );
          })}
        </tbody>
      </table> */}

      {addDependentModal && (
        <Modal isOpen={addDependentModal} onRequestClose={handleAddDependentClose} style={modalStyles2}>
          <h2>Add Dependent</h2>
          <form onSubmit={handleSaveDependent}>
            <label>
              Reason for Enrollment:
              <select value={enrollmentReason} onChange={(e) => setEnrollmentReason(e.target.value)}>
                <option value="2">Open Enrollment</option>
                <option value="3">Birth</option>
                <option value="4">Marriage</option>
                <option value="5">Loss of Coverage</option>
              </select>
            </label>

            <label>
              Gender:
              <select value={gender} onChange={(e) => setGender(e.target.value)}>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </label>

            {["2", "5"].includes(enrollmentReason) ? (
              <label>
                Relationship:
                <select value={relationship} onChange={(e) => setRelationship(e.target.value)}>
                  <option value="P">Spouse</option>
                  <option value="C">Child</option>
                  <option value="P">Other</option>
                  <option value="D">Domestic Partner</option>
                </select>
              </label>
            ) : null}

            {["2", "4", "5"].includes(enrollmentReason) ? (
              <label>
                Coverage Date:
                <input type="date" value={coverageDate} onChange={(e) => setCoverageDate(e.target.value)} />
              </label>
            ) : null}

            <label>
              SSN:
              <input type="text" value={dependentSSN} onChange={(e) => setDependentSSN(e.target.value)} />
            </label>

            <label>
              Date of Birth:
              <input type="date" value={dependentDOB} onChange={(e) => setDependentDOB(e.target.value)} />
            </label>

            <label>
              First Name:
              <input type="text" value={dependentFirstName} onChange={(e) => setDependentFirstName(e.target.value)} />
            </label>

            <label>
              Last Name:
              <input type="text" value={dependentLastName} onChange={(e) => setDependentLastName(e.target.value)} />
            </label>

            <button type="submit">Add Dependent</button>
            <button type="button" onClick={handleAddDependentClose}>
              Cancel
            </button>
          </form>
        </Modal>
      )}
      {showCoverageModal && (
        <Modal isOpen={showCoverageModal} onRequestClose={handleShowCoverageClose} style={modalStyles}>
          <div style={{ height: "100%" }}>
            {selectedMember && selectedMember.flags.length > 0 && (
              <Grid padding={10} container spacing={2}>
                {selectedMember.flags.map((flag: Flag) => (
                  <>
                    <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
                      <CheckCircleOutline />
                    </Grid>
                    <Grid item xs={6}>
                      {flag.name}
                    </Grid>
                  </>
                ))}
              </Grid>
            )}
            <button
              style={{ position: "absolute", bottom: "10px", left: "10px" }}
              type="button"
              onClick={handleShowCoverageClose}
            >
              Close
            </button>
          </div>
        </Modal>
      )}

      {showTerminateForm && (
        <Modal isOpen={showTerminateForm} onRequestClose={() => setShowTerminateForm(false)} style={modalStyles}>
          <h2>Terminate Membership</h2>
          <form onSubmit={handleTerminateSubmit}>
            <label>
              Include Cobra for the Employee?
              <RadioGroup
                row
                aria-label="cobra"
                name="cobra"
                value={isCobra}
                onChange={(e) => setIsCobra(e.target.value)}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </label>
            {isCobra === "true" ? (
              <>
                <h5>Enter the Employee's Date of Termination and Reason</h5>
                <label>
                  Select Emplyee Termination Reason:
                  <select value={termReason} onChange={(e) => setTermReason(e.target.value)}>
                    <option value="63"> Termination</option>
                    <option value="64"> Termination Involuntary</option>
                    <option value="65">Reduction in Hours - Status Change</option>
                    <option value="66">Reduction in Hours - End of Leave</option>
                    <option value="67"> Retirement</option>
                    <option value="68"> Loss of Eligibility</option>
                    <option value="69"> Bankruptcy</option>
                    <option value="70"> Reduction in Force</option>
                    <option value="71"> USERRA - Reduction in Hours</option>
                    <option value="72"> USERRA - Termination</option>
                    <option value="73"> State Continuation</option>
                    <option value="74"> Work Stoppage</option>
                    <option value="75"> Termination with Severance</option>
                    <option value="76"> Retiree Bankruptcy</option>
                  </select>
                </label>
                <label>
                  (Optional) Enter the Employee's Email:
                  <input type="text" value={cobraEmail} onChange={(e) => setCobraEmail(e.target.value)} />
                </label>
              </>
            ) : null}
            <label>
              Terminate Date:
              <Tooltip title="Please Click On Calender Icon on the right to use Date Picker">
                <input
                  ref={terminateDateRef}
                  type="date"
                  value={terminateDate}
                  onChange={handleTerminateDateChange}
                  // onFocus={handleInputFocus}
                  // onBlur={handleInputBlur}
                  // readOnly={!isInputInteractive}
                  // onMouseOver={handleMakeInteractive}
                  // onMouseOut={handleMakeReadOnly}
                  // onKeyDown={(e) => e.preventDefault()}
                />
              </Tooltip>
              <span id="dateError" style={{ color: "red" }}></span>
            </label>

            {errorMessage && (
              <div
                style={{
                  color: "red",
                  padding: "10px",
                  border: "1px solid red",
                  borderRadius: "5px",
                  marginBottom: "20px",
                }}
              >
                {errorMessage}
              </div>
            )}

            <button
              type="button"
              onClick={handleClearDate}
              style={{
                flexGrow: 1,
                padding: "10px",
                borderRadius: "5px",
                border: "none",
                backgroundColor: "#007bff",
                color: "white",
                cursor: "pointer",
              }}
            >
              Clear Date
            </button>

            {/* <label>
              All Terminations are effective by the End of the Month, therefore only the end of the month can be
              selected
            </label> */}
            <button type="submit">Confirm Termination</button>
            <button type="button" onClick={() => setShowTerminateForm(false)}>
              Cancel
            </button>
          </form>
        </Modal>
      )}

      {showOrderCardForm && (
        <Modal
          isOpen={showOrderCardForm}
          onRequestClose={() => setShowOrderCardForm(false)}
          style={customStyles} // Add custom styles
        >
          <div className="modal-container">
            {" "}
            {/* Add a container for better styling */}
            <h2>Order Card</h2>
            <form onSubmit={handleOrderCardSubmit} className="order-card-form">
              {" "}
              {/* Add a class for styling */}
              <div className="form-group">
                <label>
                  Member: {selectedMember?.firstname} {selectedMember?.lastname}
                </label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label style={{ marginRight: "8px" }}>Need Temporary Card?</label>
                <input
                  type="checkbox"
                  checked={tempCardNeeded}
                  onChange={handleTempCardChange}
                  style={{ marginTop: "-1px" }}
                />
              </div>
              {tempCardNeeded && (
                <div>
                  <div className="form-group">
                    {/* <label>
                      Email:
                      <input type="email" value={email} onChange={handleEmailChangeOrderCard} required />
                    </label> */}
                  </div>
                  <div className="form-actions">
                    <button type="button" className="btn-confirm" onClick={downloadPDF}>
                      Download Temp Card
                    </button>
                  </div>
                </div>
              )}
              <div className="form-actions">
                <button type="submit" className="btn-confirm">
                  Confirm Order
                </button>
                <button type="button" className="btn-cancel" onClick={() => setShowOrderCardForm(false)}>
                  Cancel
                </button>
                {/* <button type="button" className="btn-confirm" onClick={downloadPDF}>
                  Download Member Info
                </button> */}
              </div>
            </form>
          </div>
        </Modal>
      )}

      <Modal
        isOpen={showEditForm}
        onRequestClose={closeModal}
        contentLabel="Edit Member Modal"
        className="modal-content3" // Add this line
      >
        <h2>Edit Member</h2>
        <form onSubmit={handleSaveEdit}>
          <label>
            Last Name:
            <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </label>
          <label>
            Address One:
            <input type="text" value={addressOne} onChange={(e) => setAddressOne(e.target.value)} />
          </label>
          <label>
            City:
            <input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
          </label>
          <label>
            State:
            <input type="text" value={state} onChange={(e) => setState(e.target.value)} />
          </label>
          <label>
            Zip Code:
            <input type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
          </label>
          <br />
          <button type="submit">Save Changes</button>
          <button onClick={closeModal}>Close</button>
        </form>
      </Modal>
    </div>
  );
};

export default WLTApiDataComponent;
