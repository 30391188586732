import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridToolbar, GridRenderCellParams } from "@mui/x-data-grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

//import GlobalStyles from "@mui/material/GlobalStyles";
// import "./dataComponent.css";
import "./MUIstyling.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import GlobalStyles from "@mui/material/GlobalStyles";

// Define the custom theme
// const theme = createTheme({
//   components: {
//     MuiDataGrid: {
//       styleOverrides: {
//         columnHeaders: {
//           '& .MuiDataGrid-sortIcon': {
//             color: 'inherit', // Default color
//             '&:hover': {
//               color: 'green', // Change to green on hover
//             },
//           },
//         },
//       },
//     },
//   },

export type DataType = {
  uid: string; // Unique identifier
  Ind: string;
  Last_Name: string;
  First_Name: string;
  Status: string;
  Claim_Number: number;
  Paid_Date: string;
  Provider_Name: string;
  Tax_ID: string;
  Provider_Addr1: string;
  Provider_City: string;
  Provider_State: string;
  Provider_Zip: string;
  Service_From: string;
  Service_Thru: string;
  Billed_Amount: number;
  Paid_Amount: number;
  Not_Covered_Amount: number;
  Discount_Amount: number;
  Co_Pay_Amount: number;
  Applied_to_Ded: number;
  Deductible_Remaining: number;
  Copay_Remaining: number | null;
  Visits_Remaining: number | null;
  Relationship?: string; // Add this field if it's not already in your data
};
type ApiDataComponentProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  data: DataType[];
};

type RowDetailsDialogProps = {
  open: boolean;
  onClose: () => void;
  selectedRow: DataType | null;
  relatedClaims: DataType[]; // Add this line to hold all related claims
};
interface fieldsToDisplay {
  Ind: string;
  Last_Name: string;
  Status: string;
  Claim_Number: string;
  Paid_Amount: string;
  Billed_Amount: string;
  Not_Covered_Amount: string;
  Co_Pay_Amount: string;
  Paid_Date: string;
  Date_Of_Service: string;
  Provider_Name: string;
}

function determinePrimaryOrDependent(row: DataType): string {
  // Implement the logic to determine if it's "Primary" or "Dependent"
  return Number(row.Relationship) === 0 ? "Primary" : "Dependent"; // Example logic
}

function formatData(input: DataType): DataType {
  return {
    ...input,
    Copay_Remaining: input.Copay_Remaining ?? 0, // Replace null with 0
    Visits_Remaining: input.Visits_Remaining ?? 0, // Replace null with 0
    Relationship: determinePrimaryOrDependent(input), // Calculate the new field
  };
}

const moneyFields = ["Paid_Amount", "Billed_Amount", "Not_Covered_Amount", "Co_Pay_Amount"];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// const theme = createTheme({
//   // any other theme customizations can go here
// });

// // Global style overrides specifically for DataGrid components
// const globalStyles = (
//   <GlobalStyles
//     styles={{
//       ".MuiDataGrid-columnHeaders .MuiDataGrid-sortIcon": {
//         color: "inherit", // Default sort icon color
//         "&:hover": {
//           color: "green", // Sort icon color changes to green on hover
//         },
//       },
//     }}
//   />
// );

declare module "@mui/material/styles" {
  interface Components {
    // This should extend the existing MUI theme types with the new `MuiDataGrid` component.
    MuiDataGrid?: {
      styleOverrides?: {
        // Your style overrides here
        columnHeaders: {
          "& .MuiDataGrid-sortIcon": {
            color: "white"; // Default color
            "&:hover": {
              color: "white"; // Change to white on hover
            };
          };
        };
      };
    };
  }
}

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          "& .MuiDataGrid-sortIcon": {
            color: "white",
            "&:hover": {
              color: "white",
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "white", // For other icon buttons
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
            color: "white",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectIcon: {
          color: "black",
        },
        select: {
          color: "black",
        },
        actions: {
          "& .MuiIconButton-root": {
            color: "black", // Ensures the pagination arrows are always black
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)", // Adds a subtle background on hover
              color: "black", // Keeps the icon black on hover
            },
          },
        },
      },
    },
  },
});

// const theme = createTheme({
//   components: {
//     // Override styles for DataGrid here
//     MuiDataGrid?: {
//       styleOverrides: {
//         columnHeaders: {
//           "& .MuiDataGrid-sortIcon": {
//             color: "inherit", // Default color
//             "&:hover": {
//               color: "white", // Change to white on hover
//             },
//           },
//         },
//       },
//     },
//   },
// });

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// const handleOpen = (row: DataType) => {
//   console.log("Row data:", row);
//   // Add your logic here to handle the row data
// };

console.log("headCells:  HELLO");

const fieldsToShow = [
  "Service_From",
  "Service_Thru",
  "Billed_Amount",
  "Not_Covered_Amount",
  "Discount_Amount",
  "Co_Pay_Amount",
  "Applied_to_Ded",
  "Deductible_Remaining",
  "Copay_Remaining",
  "Visits_Remaining",
];

// type RowDetailsDialogProps = {
//   open: boolean;
//   onClose: () => void;
//   selectedRow: DataType | null;
// };

const RowDetailsDialog: React.FC<RowDetailsDialogProps> = ({ open, onClose, selectedRow, relatedClaims }) => {
  console.log("RowDetailsDialog:  HELLO");
  console.log("selectedRow: ", selectedRow);
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title" maxWidth="md" fullWidth>
      <DialogTitle id="dialog-title">Additional Claims Information</DialogTitle>
      <DialogContent>
        {selectedRow && (
          <Grid container spacing={2}>
            {/* Provider Information */}
            <Grid item xs={12}>
              <Typography variant="h6">Provider Information</Typography>
              <Divider />
              <Typography variant="body1">
                <strong>Tax ID:</strong> {selectedRow.Tax_ID}
              </Typography>
              <Typography variant="body1">
                <strong>Provider Name:</strong> {selectedRow.Provider_Name}
              </Typography>
              <Typography variant="h6" style={{ marginTop: "16px" }}>
                Provider Address
              </Typography>
              <Divider />
              <Typography variant="body1">{selectedRow.Provider_Addr1}</Typography>
              <Typography variant="body1">{`${selectedRow.Provider_City}, ${selectedRow.Provider_State} ${selectedRow.Provider_Zip}`}</Typography>
            </Grid>

            {/* EOB Summary */}
            <Grid item xs={12}>
              <Typography variant="h6">EOB Summary for Claim:</Typography>
              <Divider />
              <Typography variant="body1">
                <strong>Claim Number:</strong> {selectedRow.Claim_Number}
              </Typography>
              {/* <Typography variant="body1">
                <strong>Charge Amount:</strong> {selectedRow.Billed_Amount}
              </Typography> */}
              {/* <Typography variant="body1">
                <strong>Paid Amount:</strong> {selectedRow.Paid_Amount}
              </Typography> */}
              <Typography variant="body1">
                <strong>Paid Status:</strong> {selectedRow.Status}
              </Typography>
            </Grid>

            {/* EOB Line Items */}
            <Grid item xs={12}>
              <Typography variant="h6">EOB Line Items</Typography>
              <Divider />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Service From</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Service Thru</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Paid Status</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Total Charges</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Not Covered</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Discount/Penalty</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Applied to Ded</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Benefits Paid</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Additional Notes</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {relatedClaims.map((claim, index) => (
                      <TableRow key={index}>
                        <TableCell>{claim.Service_From}</TableCell>
                        <TableCell>{claim.Service_Thru}</TableCell>
                        <TableCell>{claim.Status}</TableCell>
                        <TableCell>{claim.Billed_Amount}</TableCell>
                        <TableCell>{claim.Not_Covered_Amount}</TableCell>
                        <TableCell>{claim.Discount_Amount}</TableCell>
                        <TableCell>{claim.Applied_to_Ded}</TableCell>
                        <TableCell>{claim.Paid_Amount}</TableCell>
                        <TableCell>{claim.Copay_Remaining}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function removeDuplicateClaims(data: DataType[]): DataType[] {
  const seen = new Set<number>();
  return data.filter((item) => {
    const duplicate = seen.has(item.Claim_Number);
    seen.add(item.Claim_Number);
    return !duplicate;
  });
}

export const DataGridDemo = (props: { data: DataType[] }) => {
  const filteredRows = removeDuplicateClaims(props.data.map((entry) => formatData(entry)));

  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<DataType | null>(null);
  const [relatedClaims, setRelatedClaims] = React.useState<DataType[]>([]);

  const handleOpen = (row: DataType) => {
    const claimsWithSameNumber = props.data.filter((claim) => claim.Claim_Number === row.Claim_Number);
    setSelectedRow(row);
    setRelatedClaims(claimsWithSameNumber);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const headCells: GridColDef[] = [
    // { field: "Ind", headerName: "Ind", width: 90, editable: false, headerClassName: "super-app-theme--header" },
    {
      field: "More Info",
      headerName: "More Info",
      width: 140,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <strong>{params.value}</strong>
          <Button variant="contained" size="small" style={{ marginLeft: 16 }} onClick={() => handleOpen(params.row)}>
            Open
          </Button>
        </Box>
      ),
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Relationship",
      headerName: "Relationship",
      width: 130,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Last_Name",
      headerName: "Last Name",
      width: 140,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "First_Name",
      headerName: "First Name",
      width: 140,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    { field: "Status", headerName: "Status", width: 130, editable: false, headerClassName: "super-app-theme--header" },
    {
      field: "Claim_Number",
      headerName: "Claim Number",
      width: 150,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Paid_Amount",
      headerName: "Paid Amount",
      width: 150,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Paid_Date",
      headerName: "Paid Date",
      width: 150,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Provider_Name",
      headerName: "Provider Name",
      width: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    { field: "Tax_ID", headerName: "Tax ID", width: 150, editable: false, headerClassName: "super-app-theme--header" },
    {
      field: "Provider_Addr1",
      headerName: "Provider Address",
      width: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Provider_City",
      headerName: "City",
      width: 150,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Provider_State",
      headerName: "State",
      width: 100,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Provider_Zip",
      headerName: "ZIP",
      width: 120,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "Service_From",
    //   headerName: "Service From",
    //   width: 150,
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "Service_Thru",
    //   headerName: "Service Thru",
    //   width: 150,
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "Billed_Amount",
    //   headerName: "Billed Amount",
    //   width: 150,
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    // },

    // {
    //   field: "Not_Covered_Amount",
    //   headerName: "Not Covered Amount",
    //   width: 180,
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "Discount_Amount",
    //   headerName: "Discount Amount",
    //   width: 150,
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "Co_Pay_Amount",
    //   headerName: "Co-Pay Amount",
    //   width: 150,
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "Applied_to_Ded",
    //   headerName: "Applied to Deductible",
    //   width: 200,
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "Deductible_Remaining",
    //   headerName: "Deductible Remaining",
    //   width: 200,
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "Copay_Remaining",
    //   headerName: "Copay Remaining",
    //   width: 150,
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "Visits_Remaining",
    //   headerName: "Visits Remaining",
    //   width: 150,
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    // },
  ];
  function getRowIds(row) {
    return row.Ind;
  }
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: 650,
          width: "89%", // Updated to 100%
          backgroundColor: "#f8f8f8",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // Enhanced shadow
          padding: "20px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          "& .super-app-theme--header": {
            backgroundColor: "#004e9d",
            color: "#ffffff",
            padding: "10px 20px",
            boxShadow: "0 3px 6px rgba(0,0,0,0.2)", // Enhanced shadow
            fontWeight: "bold",
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #e0e0e0",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            color: "#333",
          },
        }}
      >
        <DataGrid
          sx={{
            height: "auto", // Allows DataGrid to grow with content up to the Box height
            width: "100%", // Ensures DataGrid uses full width of the Box, considering padding
            "& .MuiDataGrid-virtualScroller": {
              marginTop: "0 !important", // Adjusts the header alignment issue
            },
          }}
          rows={filteredRows}
          columns={headCells}
          getRowId={(filteredRows) => filteredRows.uid}
          disableColumnSelector
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5]}
          slots={{ toolbar: GridToolbar }}
          disableRowSelectionOnClick
        />
      </Box>

      <RowDetailsDialog open={open} onClose={handleClose} selectedRow={selectedRow} relatedClaims={relatedClaims} />
    </ThemeProvider>
  );
};

export default DataGridDemo;
